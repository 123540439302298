import "../App.css";
import React, { useState, useEffect } from "react";
import { fetchData, getUserDetails } from "../helpers";
import { Container } from "../ui/Container";
import { Section, SectionTitle } from "../ui/Section";
import CmpVisitors from "../components/CmpVisitors";
import CmpDevices from "../components/CmpDevices";
import CmpKeywords from "../components/CmpKeywords";
import { Alert } from "../ui/Alert";

function Statistiken() {
  const [mounted, setMounted] = useState(false);
  const [projectsData, setProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState();
  const [projecttiles, setProjectTiles] = useState();
  const [errorCode, setErrorCode] = useState(0);
  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    getProjects();
  }, []);

  async function getProjects() {
    var data = [];
    var result;
    if (getUserDetails()) {
      try {
        result = await fetchData(
          process.env.REACT_APP_API_URL +
            "/user/" +
            JSON.parse(localStorage.getItem("user-details"))["id"] +
            "/projects",
          {
            method: "GET",
          }
        )
          .then((res) => {
            setErrorCode(res.status);
            return res.json();
          })
          .catch((error) => {
            console.log("req failed", error);
          });
        for (var ii = 0; ii < result.projects.length; ii++) {
          data.push(result.projects[ii]);
        }
        setProjects(data);
        var cur = data[0];
        setCurrentProject(cur);
        setProjectTiles(cur.tiles);
        setMounted(true);
      } catch (e) {
        console.log(e);
        setErrorState(true);
      }
    }
  }

  const projectsDropdownData = projectsData.map((project, id) => {
    return (
      <option key={"project-dropdown-id-" + id} value={project.id}>
        {project.name}
      </option>
    );
  });

  //handle dropdown change
  function handleSelectChange(event) {
    setCurrentProject(
      projectsData.filter((element) => {
        return element.id === parseInt(event.target.value);
      })[0]
    );
  }

  return (
    <div className="pt-14">
      {errorState ? (
        <Section>
          <Container>
            <Alert type="secondary">
              Etwas ist schief gelaufen. Versuchen Sie es später noch einmal.
            </Alert>
          </Container>
        </Section>
      ) : (
        <div>
          {errorCode >= 400 ? (
            <Section>
              <Container>
                <SectionTitle>{errorCode}</SectionTitle>
                <h1>
                  Etwas ist schief gelaufen. Versuchen Sie es später noch
                  einmal.
                </h1>
              </Container>
            </Section>
          ) : (
            <div>
              {mounted ? (
                <div>
                  <Container>
                    {projectsData !== undefined ? (
                      <select
                        className="rounded-md p-2.5 w-full bg-hpl_bg text-hpl font-bold hover:bg-gray-300 hover:text-gray-800 border-none text-center text-xl"
                        name="project"
                        defaultValue={currentProject.id}
                        selected={currentProject.id}
                        onChange={handleSelectChange}
                      >
                        {projectsDropdownData}
                      </select>
                    ) : (
                      <>
                        <p>Kein Projekt gefunden!</p>
                      </>
                    )}
                    <div className="">
                      {" "}
                      {projecttiles.tracking_google_analytics ? (
                        <>
                          <div className="mt-4">
                            <Alert type="secondary">
                              Viele unserer Daten werden über Google Analytics
                              bereitgestellt. Diese können unter Umständen nicht
                              die exakten Zahlen wiedergeben. Außerdem können
                              sie durch Bots verfälscht werden.
                            </Alert>
                          </div>
                          <div className="grid grid-cols-2">
                            <div className="pr-8">
                              {projecttiles.visitors ? (
                                <CmpVisitors
                                  key={
                                    "project-" + currentProject.id + "-visitors"
                                  }
                                  CurrentProject={currentProject}
                                />
                              ) : (
                                <></>
                              )}
                              {projecttiles.default_keywords ? (
                                <CmpKeywords
                                  key={
                                    "project-" + currentProject.id + "-keywords"
                                  }
                                  CurrentProject={currentProject}
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="pl-8">
                              {" "}
                              {projecttiles.devices ? (
                                <CmpDevices
                                  key={
                                    "project-" + currentProject.id + "-devices"
                                  }
                                  CurrentProject={currentProject}
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {projecttiles.tracking_etracker ? (
                        <>
                          <div className="mt-4">
                            <Alert type="secondary">
                              Sie möchten diese Ansicht noch detaillierter
                              gestalten? Wir unterstützen Sie gerne bei der
                              Einrichtung/Anbindung von Google Analytics.
                            </Alert>
                          </div>
                          <div className="grid grid-cols-2">
                            {projecttiles.default_keywords ? (
                              <CmpKeywords
                                key={
                                  "project-" + currentProject.id + "-keywords"
                                }
                                CurrentProject={currentProject}
                              />
                            ) : (
                              <></>
                            )}

                            <div className="pl-8"> </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    {(!projecttiles.visitors && !projecttiles.devices) ||
                    (!projecttiles.tracking_google_analytics &&
                      !projecttiles.tracking_etracker) ? (
                      <div className="text-center text-hpl">
                        <p>
                          Sie möchten diese Ansicht noch detaillierter
                          gestalten? Wir unterstützen Sie gerne bei der
                          Einrichtung/Anbindung von Google Analytics.
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Container>
                </div>
              ) : (
                <div>
                  <Container>Loading...</Container>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Statistiken;
