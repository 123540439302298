import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { fetchData, getUserDetails, getRole } from "../../helpers";
import { Section } from "../../ui/Section";
import { Container } from "../../ui/Container";
import { Button } from "../../ui/Button";
import { Modal, ModalHeader } from "../../ui/Modal";
import { CardBody } from "../../ui/Card";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import CmpProjectAdminTracking from "./CmpProjectAdminTracking";
import CmpProjectAdminEtracking from "./CmpProjectAdminEtracking";

import "../../App.css";

function CmpProjectAdmin() {
  const history = useHistory();
  var id = window.location.pathname.split("/")[2];
  const [mountedProject, setMountedProject] = useState(false);
  const [roleData, setRole] = useState([]);
  const [project, setProject] = useState([]);
  const [stateProjectUserID] = useState(project.user_id);
  const [stateProjectName, setStateProjectName] = useState(project.name);
  const [stateProjectViewID, setStateProjectViewID] = useState(
    project.google_analytics_view_id
  );
  const [stateProjectSiteURL, setStateProjectSiteURL] = useState(
    project.site_url
  );
  const [stateProjectDatePubl, setStateProjectDatePubl] = useState(
    project.publication_date
  );
  const [stateProjectLatestDateRoutine, setStateProjectLatestDateRoutine] =
    useState(project.latest_routine_check);
  const [stateProjectNextDateRoutine, setStateProjectNextDateRoutine] =
    useState(project.latest_routine_check);
  const [stateProjectDatePrivacy, setStateProjectDatePrivacy] = useState(
    project.latest_data_privacy_check
  );
  const [stateProjecttiles, setStateProjecttiles] = useState({});
  const [stateProjectCustomPrices, setStateProjectCustomPrices] = useState({});

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getRole().then((result) => {
      setRole(result);
    });

    if (getUserDetails()) {
      fetchData(process.env.REACT_APP_API_URL + "/projects/" + id, {
        method: "GET",
      })
        .then((data) => data.json())
        .then(function (data) {
          setProject(data.data);
          if (typeof data.data.tiles === "string") {
            setStateProjecttiles(JSON.parse(data.data.tiles));
          } else {
            setStateProjecttiles(data.data.tiles);
          }
          if (typeof data.data.custom_prices === "string") {
            setStateProjectCustomPrices(JSON.parse(data.data.custom_prices));
          } else {
            setStateProjectCustomPrices(data.data.custom_prices);
          }
          setMountedProject(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }, [id]);

  //update Project function
  function onSave(event) {
    event.preventDefault();
    var projectObj = {};
    projectObj.user_id = stateProjectUserID;
    projectObj.name = stateProjectName;
    projectObj.google_analytics_view_id = stateProjectViewID;
    projectObj.site_url = stateProjectSiteURL;
    projectObj.publication_date = stateProjectDatePubl;
    projectObj.latest_routine_check = stateProjectLatestDateRoutine;
    projectObj.latest_data_privacy_check = stateProjectDatePrivacy;
    projectObj.next_routine_check = stateProjectNextDateRoutine;
    projectObj.tiles = stateProjecttiles;
    projectObj.custom_prices = stateProjectCustomPrices;

    fetchData(process.env.REACT_APP_API_URL + "/projects/" + project.id, {
      method: "PUT",
      body: JSON.stringify(projectObj),
    })
      .then((response) => response.json())
      .then((response) => event.preventDefault());
  }

  function onDelete() {
    fetchData(process.env.REACT_APP_API_URL + "/projects/" + project.id, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((response) => "Deleted!" + response);

    history.push("/projekte");
  }

  function handleChangeProjecttiles(event) {
    var vName = event.target.name.split(["-"], 2)[1];
    if (!(vName in stateProjecttiles)) {
      stateProjecttiles[vName] = false;
    }
    stateProjecttiles[vName] = !stateProjecttiles[vName];
  }

  return (
    <div className="">
      {roleData === "admin" ? (
        mountedProject ? (
          <Section>
            <Container>
              <Modal open={isOpen} className="p-3">
                <ModalHeader
                  heading="Dieses Projekt löschen? &nbsp;"
                  onClose={() => setIsOpen(false)}
                />

                <CardBody className="">
                  <Button variant="primary" className="" onClick={onDelete}>
                    Projekt löschen
                  </Button>
                </CardBody>
              </Modal>
              <div className="flex mb-8">
                <h1 className="text-4xl font-bold w-10/12">{project.name}</h1>{" "}
                <div className="flex-auto w-2/12 p-0 font-bold">
                  <div className="float-right w-full">
                    <Button
                      variant="primary"
                      type=""
                      className="float-right"
                      onClick={() => setIsOpen(true)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} className="" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="text-xl font-bold mb-12">
                <div className="">
                  <Link to={"/projectpreview/" + id}>
                    <Button variant="primary" type="" className="">
                      Projekt ansehen
                    </Button>
                  </Link>
                </div>
              </div>
              <form>
                <div className="grid grid-cols-3 gap-4 mb-8">
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="projectName"
                    >
                      Name
                    </label>
                    <input
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                      id="projectName"
                      type="text"
                      placeholder={project.name}
                      defaultValue={project.name}
                      //value={stateProjectName}
                      onChange={(e) => setStateProjectName(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="siteUrl"
                    >
                      Seiten-URL
                    </label>
                    <input
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                      name="site_url"
                      type="text"
                      placeholder={project.site_url}
                      defaultValue={project.site_url}
                      //value={stateProjectSiteURL}
                      onChange={(e) => setStateProjectSiteURL(e.target.value)}
                    />
                  </div>
                  <div className="flex">
                    <div className="w-4/5">
                      <label
                        className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="gaViewId"
                      >
                        Google Analytics View ID
                      </label>
                      <input
                        className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                        name="google_analytics_view_id"
                        type="text"
                        placeholder={project.google_analytics_view_id}
                        defaultValue={project.google_analytics_view_id}
                        //value={stateProjectViewID}
                        onChange={(e) => setStateProjectViewID(e.target.value)}
                      />
                    </div>
                    <div className="w-1/5 ml-4">
                      <label
                        className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="gaViewId"
                      >
                        GA4
                      </label>
                      <input
                        className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                        name="check-GA4"
                        type="checkbox"
                        defaultChecked={stateProjecttiles.GA4}
                        value={stateProjecttiles.GA4}
                        onChange={(e) => handleChangeProjecttiles(e)}
                      />
                    </div>
                  </div>

                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="publication_date"
                    >
                      Veröffentlichungsdatum
                    </label>
                    <input
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                      name="publication_date"
                      type="date"
                      defaultValue={project.publication_date.substr(0, 10)}
                      onChange={(e) => setStateProjectDatePubl(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="latest_routine_check"
                    >
                      Letzter Routine-Check
                    </label>
                    <input
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                      name="latest_routine_check"
                      type="date"
                      defaultValue={project.latest_routine_check.substr(0, 10)}
                      onChange={(e) =>
                        setStateProjectLatestDateRoutine(e.target.value)
                      }
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="next_routine_check"
                    >
                      Nächster Routine-Check
                    </label>
                    <input
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                      name="next_routine_check"
                      type="date"
                      defaultValue={project.next_routine_check.substr(0, 10)}
                      onChange={(e) =>
                        setStateProjectNextDateRoutine(e.target.value)
                      }
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="latest_data_privacy_check"
                    >
                      Letzter Datenschutz-Check
                    </label>
                    <input
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                      name="latest_data_privacy_check"
                      type="date"
                      defaultValue={project.latest_data_privacy_check.substr(
                        0,
                        10
                      )}
                      onChange={(e) =>
                        setStateProjectDatePrivacy(e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="flex mb-4">
                  <h2 className="text-3xl font-bold">Anzuzeigende Tiles</h2>{" "}
                </div>
                <div className="grid grid-cols-3 gap-4 mb-8">
                  <label className=" text-m mb-2" htmlFor="check-website">
                    <input
                      className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                      name="check-website"
                      type="checkbox"
                      defaultChecked={stateProjecttiles.website}
                      value={stateProjecttiles.website}
                      onChange={(e) => {
                        handleChangeProjecttiles(e);
                      }}
                    />
                    &nbsp; Webseite
                  </label>
                  <label className=" text-m mb-2" htmlFor="check-visitors">
                    <input
                      className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                      name="check-visitors"
                      type="checkbox"
                      defaultChecked={stateProjecttiles.visitors}
                      value={stateProjecttiles.visitors}
                      onChange={(e) => handleChangeProjecttiles(e)}
                    />
                    &nbsp; Besucherzahlen
                  </label>
                  <label
                    className=" text-m mb-2"
                    htmlFor="check-default_keywords"
                  >
                    <input
                      className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                      name="check-default_keywords"
                      type="checkbox"
                      defaultChecked={stateProjecttiles.default_keywords}
                      value={stateProjecttiles.default_keywords}
                      onChange={(e) => handleChangeProjecttiles(e)}
                    />
                    &nbsp; Allgemeine Top Keywords
                  </label>
                  <label
                    className=" text-m mb-2"
                    htmlFor="check-google_references"
                  >
                    <input
                      className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                      name="check-google_references"
                      type="checkbox"
                      defaultChecked={stateProjecttiles.google_references}
                      value={stateProjecttiles.google_references}
                      onChange={(e) => handleChangeProjecttiles(e)}
                    />
                    &nbsp; Google Referenzen
                  </label>
                  <label className=" text-m mb-2" htmlFor="check-tickets">
                    <input
                      className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                      name="check-tickets"
                      type="checkbox"
                      defaultChecked={stateProjecttiles.tickets}
                      value={stateProjecttiles.tickets}
                      onChange={(e) => handleChangeProjecttiles(e)}
                    />
                    &nbsp; Tickets
                  </label>
                  <label className=" text-m mb-2" htmlFor="check-devices">
                    <input
                      className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                      name="check-devices"
                      type="checkbox"
                      defaultChecked={stateProjecttiles.devices}
                      value={stateProjecttiles.devices}
                      onChange={(e) => handleChangeProjecttiles(e)}
                    />
                    &nbsp; Geräte
                  </label>
                  <label
                    className=" text-m mb-2"
                    htmlFor="check-digital_strategy"
                  >
                    <input
                      className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                      name="check-digital_strategy"
                      type="checkbox"
                      defaultChecked={stateProjecttiles.digital_strategy}
                      value={stateProjecttiles.digital_strategy}
                      onChange={(e) => handleChangeProjecttiles(e)}
                    />
                    &nbsp; Digitalstrategie
                  </label>
                  <label className=" text-m mb-2" htmlFor="check-automations">
                    <input
                      className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                      name="check-automations"
                      type="checkbox"
                      defaultChecked={stateProjecttiles.automations}
                      value={stateProjecttiles.automations}
                      onChange={(e) => handleChangeProjecttiles(e)}
                    />
                    &nbsp; Automatisierungsideen
                  </label>
                  <label
                    className="text-m mb-2"
                    htmlFor="check-next_routine_check"
                  >
                    <input
                      className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                      name="check-next_routine_check"
                      type="checkbox"
                      defaultChecked={stateProjecttiles.next_routine_check}
                      value={stateProjecttiles.next_routine_check}
                      onChange={(e) => handleChangeProjecttiles(e)}
                    />
                    &nbsp; Nächster Routinetermin
                  </label>
                </div>
                <div className="flex mb-4">
                  <h2 className="text-3xl font-bold">Tracking</h2>{" "}
                </div>
                <div className="grid grid-cols-2 gap-4 mb-8">
                  <label
                    className=" text-m mb-2"
                    htmlFor="check-tracking_google_analytics"
                  >
                    <input
                      className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                      name="check-tracking_google_analytics"
                      type="checkbox"
                      defaultChecked={
                        stateProjecttiles.tracking_google_analytics
                      }
                      value={stateProjecttiles.tracking_google_analytics}
                      onChange={(e) => {
                        handleChangeProjecttiles(e);
                      }}
                    />
                    &nbsp; Tracking über Google Analytics
                  </label>
                  <label
                    className=" text-m mb-2"
                    htmlFor="check-tracking_etracker"
                  >
                    <input
                      className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                      name="check-tracking_etracker"
                      type="checkbox"
                      defaultChecked={stateProjecttiles.tracking_etracker}
                      value={stateProjecttiles.tracking_etracker}
                      onChange={(e) => {
                        handleChangeProjecttiles(e);
                      }}
                    />
                    &nbsp; Tracking über Etracker
                  </label>
                  <label className=" text-m mb-2" htmlFor="check-added_value">
                    <input
                      className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                      name="check-added_value"
                      type="checkbox"
                      defaultChecked={stateProjecttiles.added_value}
                      value={stateProjecttiles.added_value}
                      onChange={(e) => handleChangeProjecttiles(e)}
                    />
                    &nbsp; Mehrwert
                  </label>
                  <>
                    <label
                      className=" text-m mb-2"
                      htmlFor="check-added_value_unique_visitors"
                    >
                      <input
                        className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                        name="check-added_value_unique_visitors"
                        type="checkbox"
                        defaultChecked={
                          stateProjecttiles.added_value_unique_visitors
                        }
                        value={stateProjecttiles.added_value_unique_visitors}
                        onChange={(e) => handleChangeProjecttiles(e)}
                      />
                      &nbsp; Mehrwert Unique Visitors
                    </label>
                    <label
                      className=" text-m mb-2"
                      htmlFor="check-added_value_leads"
                    >
                      <input
                        className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                        name="check-added_value_leads"
                        type="checkbox"
                        defaultChecked={stateProjecttiles.added_value_leads}
                        value={stateProjecttiles.added_value_leads}
                        onChange={(e) => handleChangeProjecttiles(e)}
                      />
                      &nbsp; Mehrwert Leads
                    </label>
                    <label
                      className=" text-m mb-2"
                      htmlFor="check-added_value_sales_calls"
                    >
                      <input
                        className="appearance-none bg-white text-gray-400 border border-gray-300 rounded py-3 px-4 
                    leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                        name="check-added_value_sales_calls"
                        type="checkbox"
                        defaultChecked={
                          stateProjecttiles.added_value_sales_calls
                        }
                        value={stateProjecttiles.added_value_sales_calls}
                        onChange={(e) => handleChangeProjecttiles(e)}
                      />
                      &nbsp; Mehrwert Vertriebsanrufe
                    </label>
                  </>
                </div>
                {stateProjecttiles.tracking_google_analytics ? (
                  <CmpProjectAdminTracking CurrentProject={project} />
                ) : (
                  <></>
                )}
                {stateProjecttiles.tracking_etracker ? (
                  <CmpProjectAdminEtracking CurrentProject={project} />
                ) : (
                  <></>
                )}

                <div className="">
                  <Button
                    variant="primary"
                    type=""
                    className=""
                    onClick={onSave}
                  >
                    Speichern
                  </Button>
                </div>
              </form>
            </Container>
          </Section>
        ) : (
          <div>
            <Container>Loading...</Container>
          </div>
        )
      ) : (
        <>
          <h1>Auf diese Ansicht haben Sie keinen Zugriff!</h1>
        </>
      )}
    </div>
  );
}

export default CmpProjectAdmin;
