import React, { useState, useEffect } from "react";
import { fetchData, getRole } from "../../helpers";
import { Container } from "../../ui/Container";
import { Button } from "../../ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import "../../App.css";

function CmpProjectAdminEtracking({ CurrentProject }) {
  var id = window.location.pathname.split("/")[2];
  const [mountedEtracking, setMountedEtracking] = useState(false);
  const [mountedEtrackerConfig, setMountedEtrackerConfig] = useState(false);
  const [roleData, setRole] = useState([]);
  const [project, setProject] = useState(CurrentProject);
  const [stateProjectCustomPrices, setStateProjectCustomPrices] = useState(
    CurrentProject.custom_prices
  );

  const [stateEtrackingArray, setStateEtrackingArray] = useState([]);
  const [stateEtrackerConfig, setStateEtrackerConfig] = useState({
    etracker_id: "",
    etracker_password: "",
  });

  const [errorEtracking, setErrorEtracking] = useState(false);

  useEffect(() => {
    getRole().then((result) => {
      setRole(result);
    });
    if (CurrentProject.tiles.tracking_etracker) {
      getEtrackings();
      getEtrackerConfig();
    }
  }, [id]);

  function getEtrackerConfig() {
    if (localStorage.getItem("user-info")) {
      fetchData(
        process.env.REACT_APP_API_URL +
          "/projects/" +
          CurrentProject.id +
          "/projectetrackerconfig",
        {
          method: "GET",
        }
      )
        .then((data) => data.json())
        .then(function (data) {
          setStateEtrackerConfig(data.data[0]);
          console.log(stateEtrackerConfig);
          setMountedEtrackerConfig(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }

  function postEtracking(event) {
    event.preventDefault();
    var etrackingObj = {};
    etrackingObj.project_id = project.id;
    etrackingObj.tracking_id = "-";
    etrackingObj.headline = "New Headline " + stateEtrackingArray.length;

    fetchData(process.env.REACT_APP_API_URL + "/projectetrackings", {
      method: "POST",
      body: JSON.stringify(etrackingObj),
    })
      .then((response) => response.json())
      .then((response) => getEtrackings());
  }

  function handleChangeProjectEtracking(event) {
    var value = event.target.value;
    var id = event.target.id.split("-");
    onSaveEtracking(id[0], id[2], value);
  }

  function onSaveEtracking(db_etracking_id, key, value) {
    var etrackingObj = {};
    etrackingObj[key] = value;

    fetchData(
      process.env.REACT_APP_API_URL +
        "/projects/" +
        id +
        "/etrackings/" +
        db_etracking_id,
      {
        method: "PUT",
        body: JSON.stringify(etrackingObj),
      }
    )
      .then((response) => response.json())
      .then(function (data) {
        if (data.success) {
          setErrorEtracking(false);
          return data;
        }
        setErrorEtracking(true);
      })
      .then((response) => getEtrackings());
  }

  function getEtrackings() {
    var res = [];
    if (localStorage.getItem("user-info")) {
      fetchData(process.env.REACT_APP_API_URL + "/projectetrackings", {
        method: "GET",
      })
        .then((data) => data.json())
        .then(function (data) {
          for (var ii = 0; ii < data.data.length; ii++) {
            if (data.data[ii]["project_id"] === parseInt(id)) {
              res.push(data.data[ii]);
            }
          }
          setStateEtrackingArray(res);
          setMountedEtracking(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }

  function onDeleteEtracking(id) {
    delete stateProjectCustomPrices["etracker_tracking-" + id];
    delete stateProjectCustomPrices["DESCR:etracker_tracking-" + id];

    var projectObj = {};
    projectObj.custom_prices = stateProjectCustomPrices;

    fetchData(process.env.REACT_APP_API_URL + "/projects/" + project.id, {
      method: "PUT",
      body: JSON.stringify(projectObj),
    }).then((response) => response.json());

    fetchData(process.env.REACT_APP_API_URL + "/projectetrackings/" + id, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((response) => getEtrackings());
  }

  function handleChangeEtrackerConfig(event) {
    stateEtrackerConfig[event.target.id] = event.target.value;
  }

  function saveEtrackerConfig(event) {
    event.preventDefault();
    var configObj = {};
    configObj.project_id = CurrentProject.id;
    configObj.etracker_id = stateEtrackerConfig.etracker_id;
    if (typeof stateEtrackerConfig.etracker_password !== "undefined") {
      configObj.etracker_password = stateEtrackerConfig.etracker_password;
    }
    if (typeof stateEtrackerConfig.id !== "undefined") {
      fetchData(
        process.env.REACT_APP_API_URL +
          "/projects/" +
          CurrentProject.id +
          "/projectetrackerconfig/" +
          stateEtrackerConfig.id,
        {
          method: "PUT",
          body: JSON.stringify(configObj),
        }
      ).then((response) => response.json());
    } else {
      fetchData(process.env.REACT_APP_API_URL + "/projectetrackerconfig", {
        method: "POST",
        body: JSON.stringify(configObj),
      }).then((response) => response.json());
    }
  }

  function handleChangeProjectCustomPrices(event) {
    var eventArray = event.target.id.split(["-"], 3);
    stateProjectCustomPrices["etracker_tracking-" + eventArray[0]] = parseFloat(
      event.target.value
    );
    onSave(event);
  }

  function handleChangeProjectCustomPricesDescriptions(event) {
    var eventArray = event.target.id.split(["-"], 4);
    stateProjectCustomPrices["DESCR:etracker_tracking-" + eventArray[0]] =
      event.target.value;
    onSave(event);
  }

  //update Project function
  function onSave(event) {
    event.preventDefault();
    var projectObj = {};
    projectObj.custom_prices = stateProjectCustomPrices;

    fetchData(process.env.REACT_APP_API_URL + "/projects/" + project.id, {
      method: "PUT",
      body: JSON.stringify(projectObj),
    })
      .then((response) => response.json())
      .then((response) => event.preventDefault());
  }

  function getKey(trackingObj) {
    return "etracker_tracking-" + trackingObj.id;
  }

  const etrackingTable = stateEtrackingArray.map((trackingObj) => {
    var trackingKey = getKey(trackingObj);
    return (
      <tr key={"tracking-row-" + trackingObj.id}>
        <td className="px-3 py-2">
          {" "}
          <input
            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
            id={trackingObj.id + "-etracker-tracking_id"}
            name="tracking_id"
            type="text"
            placeholder="Tracking-ID"
            defaultValue={trackingObj.tracking_id}
            onBlur={(e) => handleChangeProjectEtracking(e)}
          />
        </td>
        <td className="px-3 py-2">
          <input
            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
            id={trackingObj.id + "-etracker-headline"}
            name="tracking_headline"
            type="text"
            placeholder="Headline"
            defaultValue={trackingObj.headline}
            onBlur={(e) => handleChangeProjectEtracking(e)}
          />
        </td>
        <td className="px-3 py-2">
          <input
            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
            id={trackingObj.id + "-etracker-params"}
            name="params"
            type="text"
            placeholder="Parameter"
            defaultValue={trackingObj.params}
            onBlur={(e) => handleChangeProjectEtracking(e)}
          />
        </td>
        <td className="px-3 py-2">
          <input
            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
            id={trackingObj.id + "-etracker-custom_price"}
            name={"etracker-custom_price"}
            type="number"
            step=".01"
            placeholder={stateProjectCustomPrices[trackingKey]}
            defaultValue={stateProjectCustomPrices[trackingKey]}
            onBlur={(e) => handleChangeProjectCustomPrices(e)}
          />
        </td>
        <td className="px-3 py-2">
          <input
            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
            id={trackingObj.id + "-etracker-custom_price-DESCR"}
            name={"etracker-custom_price-DESCR"}
            type="text"
            placeholder={stateProjectCustomPrices["DESCR:" + trackingKey]}
            defaultValue={stateProjectCustomPrices["DESCR:" + trackingKey]}
            onBlur={(e) => handleChangeProjectCustomPricesDescriptions(e)}
          />
        </td>
        <td className="px-3 py-2 w-full">
          <Button
            onClick={() => onDeleteEtracking(trackingObj.id)}
            className=""
          >
            <FontAwesomeIcon icon={faTrashAlt} className="" />
          </Button>
        </td>
      </tr>
    );
  });

  return (
    <div className="">
      {roleData === "admin" ? (
        mountedEtracking && mountedEtrackerConfig ? (
          <div>
            <div className="flex">
              <h3 className="text-2xl font-bold">Tracking über Etracker</h3>{" "}
            </div>
            <div className="flex gap-4 my-8">
              <div className="w-2/5">
                <label
                  className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="etracker_id"
                >
                  Etracker-ID
                </label>
                <input
                  className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                  id="etracker_id"
                  type="text"
                  placeholder={stateEtrackerConfig.etracker_id}
                  defaultValue={stateEtrackerConfig.etracker_id}
                  onBlur={(e) => handleChangeEtrackerConfig(e)}
                />
              </div>
              <div className="w-2/5">
                <label
                  className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="etracker_password"
                >
                  Etracker Passwort
                </label>
                <input
                  className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                  id="etracker_password"
                  type="text"
                  defaultValue={""}
                  onBlur={(e) => handleChangeEtrackerConfig(e)}
                />
              </div>
              <div className="w-1/5 relative">
                <button
                  type="button"
                  onClick={(e) => {
                    saveEtrackerConfig(e);
                  }}
                  className="absolute bottom-0 rounded-md p-2 bg-gray-200 text-white font-semibold text-gray-700 hover:bg-red-700 hover:text-white"
                >
                  Konfiguration speichern
                </button>
              </div>
            </div>
            <span></span>
            <div className="divide-gray-800">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-gray-500 uppercase tracking-wider w-2/12"
                    >
                      Tracking-ID
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-gray-500 uppercase tracking-wider w-2/12"
                    >
                      Tracking-Headline
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-gray-500 uppercase tracking-wider w-2/12"
                    >
                      Parameter
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-gray-500 uppercase tracking-wider w-2/12"
                    >
                      Eingestellter Preis in €
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-gray-500 uppercase tracking-wider w-5/12"
                    >
                      Beschreibung für Preis
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-gray-500 uppercase tracking-wider w-1/12"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {etrackingTable}
                </tbody>
              </table>
              <hr />
              {errorEtracking ? (
                <div className="text-red-900 bg-red-200 rounded-sm -m-0.5 px-4">
                  <span className="">
                    Setzen des Feldes nicht möglich. Bitte andere Bezeichnung
                    wählen.
                  </span>
                </div>
              ) : (
                <></>
              )}
              <div className="grid grid-cols-4 gap-4 px-3 py-2 mb-8">
                {/* <input
                  className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                  name="tracking_id"
                  type="text"
                  placeholder="Tracking-ID"
                  onBlur={(e) => setStateNewEtrackingID(e.target.value)}
                /> */}
                <Button
                  onClick={(e) => {
                    postEtracking(e);
                  }}
                >
                  Etracking hinzufügen
                </Button>
              </div>
            </div>

            <div className="mt-4 mb-4">
              <hr />
            </div>
          </div>
        ) : (
          <div>
            <Container>Loading...</Container>
          </div>
        )
      ) : (
        <>
          <h1>Auf diese Ansicht haben Sie keinen Zugriff!</h1>
        </>
      )}
    </div>
  );
}

export default CmpProjectAdminEtracking;
