import React, { useState, useEffect } from "react";
import { fetchData } from "../helpers";
import { Pie } from "react-chartjs-2";
import { Section, SectionTitle } from "../ui/Section";
import { Card, CardBody } from "../ui/Card";
import "../App.css";
import { LeadText } from "../ui/LeadText";

function CmpDevices({ CurrentProject }) {
  const [mounted, setMounted] = useState(false);
  const [devicesData, setDevices] = useState([]);

  useEffect(() => {
    function getDevices() {
      if (localStorage.getItem("user-info")) {
        fetchData(
          process.env.REACT_APP_API_URL +
            "/projects/" +
            CurrentProject.id +
            "/devices",
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then(function (data) {
            setDevices(data.data);
            setMounted(true);
          })
          .catch((error) => {
            console.log("req failed", error);
          });
      }
    }
    getDevices();
  }, [CurrentProject]);

  var $labelArray = Object.keys(devicesData);
  var $dataArray = Object.values(devicesData);

  // for (var ii = 0; ii < devicesData.length; ii++) {
  //   $labelArray.push(devicesData[ii].device);
  //   $dataArray.push(parseInt(devicesData[ii].count));
  // }
  var totalSum = $dataArray.reduce((a, b) => a + b, 0);

  for (var jj = 0; jj < $labelArray.length; jj++) {
    $labelArray[jj] =
      $labelArray[jj] +
      " - " +
      (($dataArray[jj] / totalSum) * 100).toFixed(1) +
      "% ";
  }

  const chart = {
    labels: $labelArray,
    datasets: [
      {
        label: "Devices",
        backgroundColor: [
          "#001BBE",
          "#436FFF",
          "#7790EB",
          "#188CFF",
          "#5030F5",
        ],
        hoverBackgroundColor: [
          "#001BBE",
          "#436FFF",
          "#7790EB",
          "#188CFF",
          "#5030F5",
        ],
        borderColor: "#fff",
        borderWidth: 1,
        data: $dataArray,
      },
    ],
  };

  return (
    <div className="">
      {mounted ? (
        <Section>
          <SectionTitle>Genutzte Geräte</SectionTitle>
          <LeadText>
            Das Diagramm zeigt die benutzen Geräte bei dem Besuch Webseite in
            der vergangenen Zeit.
          </LeadText>
          <Card className="">
            <CardBody>
              <Pie data={chart} options={{ responsive: true }} />
            </CardBody>
          </Card>
        </Section>
      ) : (
        <div>
          <Section>Loading...</Section>
        </div>
      )}
    </div>
  );
}

export default CmpDevices;
