import React from "react";
import { useEffect, useState } from "react";
import "../App.css";

import RatingpagesDropdown from "../components/RatingpagesDropdown";
import { fetchData, formatDate } from "../helpers";
import { Container } from "../ui/Container";
import { List, ListItem } from "../ui/List";
import { PageHeader, PageTitle } from "../ui/Page";
import { Section, SectionTitle } from "../ui/Section";
import { Stars } from "../ui/Stars";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faWrench } from "@fortawesome/free-solid-svg-icons";

function Bewertungen() {
  const [reviews, setReviews] = useState([]);
  const [ratingpage, setRatingpage] = useState(null);
  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    if (ratingpage === null) return;

    fetchData(
      process.env.REACT_APP_API_URL +
        "/ratingpages/" +
        ratingpage.id +
        "/reviews"
    )
      .then((res) => res.json())
      .then((res) => {
        setReviews(res);
      });
  }, [ratingpage]);

  const handleRatingpageChange = (ratingpage) => {
    setRatingpage(ratingpage);
  };

  return (
    <>
      {errorState ? (
        <Section>
          <Container>
            <SectionTitle>
              Etwas ist schief gelaufen. Versuchen Sie es später noch einmal.
            </SectionTitle>
          </Container>
        </Section>
      ) : (
        <div>
          <PageHeader>
            <PageTitle>Bewertungen</PageTitle>
            <p>
              Nachfolgend werden Ihnen Ihre neuesten Google-Bewertungen
              angezeigt. Falls Sie eine unberechtigte Bewertung entfernen lassen
              möchten, können Sie eine Löschung über bewertungsloescher.de
              beantragen.
            </p>
          </PageHeader>
          <Section>
            <Container>
              <RatingpagesDropdown onChange={handleRatingpageChange} />

              <List className="mt-8">
                {reviews.map((review) => (
                  <ListItem key={"review-" + review.id}>
                    <p>
                      <Stars stars={review.rating} />
                    </p>
                    <p>
                      <strong>{review.author_name}</strong>{" "}
                      <span>am {formatDate(review.created_at)}</span>
                    </p>

                    <p>{review.text}</p>
                    <p className="mt-4">
                      <a
                        href="https://bewertungsloescher.de/kontakt/"
                        target="_blank"
                        className="text-red-700"
                        rel="noreferrer"
                      >
                        Löschung über bewertungslöscher.de beantragen
                      </a>
                    </p>
                  </ListItem>
                ))}

                {reviews.length === 0 ? (
                  <ListItem>
                    <strong>Keine Bewertungen vorhanden</strong>
                  </ListItem>
                ) : null}
              </List>
            </Container>
          </Section>
        </div>
      )}
    </>
  );
}

export default Bewertungen;
